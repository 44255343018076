import React, {
  Fragment,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
  Search,
} from '@makeably/creativex-design-system';
import { emptyState } from 'components/internal/shared';
import { newInternalDv360CampaignPath } from 'utilities/routes';
import styles from './Dv360AdAccount.module.css';

const adAccountProps = PropTypes.shape({
  advertiserId: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  brand: PropTypes.string,
  market: PropTypes.string,
});

const propTypes = {
  adAccount: adAccountProps.isRequired,
  campaignIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const details = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'advertiserId',
    label: 'Advertiser ID',
  },
  {
    key: 'level',
    label: 'Level',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
];

function renderDetail({ key, label }, adAccount) {
  return (
    <Fragment key={key}>
      <div>{ `${label}:` }</div>
      <div>{ adAccount[key] ?? 'N/A' }</div>
    </Fragment>
  );
}

function Dv360AdAccount({ adAccount, campaignIds }) {
  const [search, setSearch] = useState('');

  const ids = useMemo(() => (
    campaignIds.slice().sort().map((id) => `${id}`)
  ), [campaignIds]);

  const searchedIds = useMemo(() => (
    ids.filter((id) => id.indexOf(search) !== -1)
  ), [ids, search]);

  return (
    <Card className="u-flexColumn u-gap-24">
      <div className="u-flexRow u-justifyEnd">
        <AddNewButton
          label="Add Campaigns"
          url={newInternalDv360CampaignPath()}
        />
      </div>
      <div>
        <h4>DV360 Ad Account</h4>
        <div className={styles.details}>
          { details.map((detail) => renderDetail(detail, adAccount)) }
        </div>
      </div>
      <div className="u-flexColumn u-gap-16">
        <Search
          placeholder="Search by Campaign ID"
          value={search}
          onChange={setSearch}
        />
        <h5>{ `${campaignIds.length} Campaigns` }</h5>
        <div className="u-flexRow u-scrollShadowRight">
          <div className={styles.campaigns}>
            { searchedIds.length === 0 && emptyState }
            { searchedIds.map((id) => (
              <div key={id}>{ id }</div>
            )) }
          </div>
        </div>
      </div>
    </Card>
  );
}

Dv360AdAccount.propTypes = propTypes;

export default Dv360AdAccount;
